/* global
autosize
bsCustomFileInput
checkRedirect
initHtmlTextarea
updateHtmlContent
*/

// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// FOCUS

$body.betterFocus({
  selector: "a, [tabindex]",
});

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM

function initFormDefaults ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  // TODO: Selber schreiben!
  autosize($("textarea", $parent));

  // HTML TinyMCE
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  $("[data-ajax-upload]", $parent).ajaxUpload({
    onUploadCompleted: function ($upload, $data) {
      updateHtmlContent($data);
    },
  });

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set

  $("[data-form-set]", $parent).formSet();
}

initFormDefaults();

$body.formNumberInput({
  selector: "[type=number]",
});

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function ($form) {
    // $("[data-ajax-upload]").ajaxUpload("reset");

    // remove shp, shx, dbf, nc and story-image input files because these files area already uploaded with ajax-upload
    for (let i = 0; i < $form[0].length; i++) {
      if (
        $form[0][i].id.endsWith("file_shp") ||
        $form[0][i].id.endsWith("file_shx") ||
        $form[0][i].id.endsWith("file_dbf") ||
        $form[0][i].id.endsWith("file_nc") ||
        $form[0][i].id === "id_contributionstorypageform-image"
      ) {
        $form[0][i].value = "";
      }
    }
  },
  afterSubmit: function (request, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        updateHtmlContent($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();

// #############################################################################
// AJAX MODAL

// TODO: selector auch bei anderen plugins übernehmen!

$body.ajaxModal({
  selector: "[data-modal-link]",
  beforeModalOpen: function ($modal, $data) {
    if ($data.submit === "error") {
      if ($data.toaster) {
        $body.toaster("updateToaster", $data.toaster);
      }
    }
  },
  onModalOpen: function ($modal) {
    $("[autofocus]", $modal).focus();
    $("[data-toggle=tooltip]", $modal).tooltip();

    initFormDefaults($modal);

    // Validation

    $("[data-form]", $modal).formValidation({
      afterSubmit: function (request, $form, $data) {
        if ($data.submit === "success") {
          $modal.modal("hide");

          if ($data.redirect) {
            checkRedirect($data);
          } else {
            updateHtmlContent($data);

            if ($data.toaster) {
              $body.toaster("updateToaster", $data.toaster);
            }

            $("[data-table]").xDataTable("reload");
          }
        }
      },
    });

    // Wizard

    $("[data-form-wizard]", $modal).formWizard();
  },
});

// #############################################################################
// DATA TABLE

$("[data-table]").xDataTable({
  options: {
    columns: [
      {
        data: "PLACEHOLDER_FIELDS",
      },
    ],
  },
  onInit: function ($table, $json) {
    // Custom code on init
  },
  onStateLoaded: function ($table, $data) {
    // Custom code on init
  },
  onDraw: function ($table) {
    // Custom code on redraw

    $("[data-toggle=tooltip]", $table).tooltip();
  },
  customizeCSV: function (csv) {
    // For customization read https://datatables.net/reference/button/csv

    return csv;
  },
  api: function ($table, $api) {
    // API: https://datatables.net/reference/api/
  },
});

// #############################################################################
// DOWNLOAD BLOB

$("[data-download]").downloadBlob({
  onDownloadStarted: function ($data) {
    $body.toaster("updateToaster", $data.toaster);
  },
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

// #############################################################
// SAVE CONTRIBUTION STATUS

$body.on("change", "#id_change_contribution_status", function () {
  const elm = $(this);
  $.ajax({
    data: {
      value: elm.val(),
    },
    type: "GET",
    url: elm.data("url"),
    success: function ($data) {
      if ($data.toaster) {
        $body.toaster("updateToaster", $data.toaster);
      }
      $("[data-table]").xDataTable("reload");
    },
  });
});
